import React from 'react';
import { Button, Divider, Typography } from '@material-ui/core';

import useStyles from './styles';
import { useGetParcelQuery, User } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';

const ShipmentCreatedSuccessfully: React.FC<{
  id: string | null;
  user: User | null | undefined;
}> = (props) => {
  const classes = useStyles();
  const { data } = useGetParcelQuery({
    variables: {
      // @ts-ignore
      id: props.id,
    },
  });

  const { t } = useTranslation();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Typography className={classes.title} variant='h2'>
            {t('app.shipmentCreated', {
              value: data?.parcel?.barcode?.substring(
                0,
                data?.parcel?.barcode.length - 4,
              ),
            })}
          </Typography>
          <Divider />
          <p className={classes.content}>{t('app.shipmentSuccess')}</p>

          <div className={classes.buttonsWrapper}>
            <Button
              disabled={!props.id}
              className={classes.printButton}
              variant='contained'
            >
              <a
                className={classes.link}
                href={`${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${data?.parcel?.barcode}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('app.barcode')}
              </a>
            </Button>
            <Button
              disabled={!props.id}
              className={classes.printButton}
              variant='contained'
            >
              <a
                className={classes.link}
                href={`${process.env.REACT_APP_API_DOMAIN}/api/getReceipt/${data?.parcel?.pathNameFile}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('app.label')}
              </a>
            </Button>
            <Button
              className={classes.okButton}
              variant='contained'
              disabled={data?.parcel?.isConsolidationPiece ? true : false}
            >
              <a
                className={classes.link}
                href={`${process.env.REACT_APP_API_DOMAIN}/api/getReceipt/invoice_${data?.parcel?.pathNameFile}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('app.invoice')}
              </a>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipmentCreatedSuccessfully;
