import React from 'react';
import moment from 'moment';
import { Link, NavLink } from 'react-router-dom';
import {
  Checkbox,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SaveIcon from '@material-ui/icons/Save';
import { Edit as EditIcon } from '@material-ui/icons';
import useStyles from './styles';
import {
  getStatusIdByName,
  handleSelectCheckboxItem,
  TRoutes,
} from '../../utils/helpers';
import {
  ConvertStatusIdToText,
  GetCarrierCodeImage,
} from '../../utils/helperComponents';
import { Parcel, StatusDelivery, UserRoles } from '../../generated/graphql';
import { TSetStateArrayString, TSetStateBoolean } from '../../interfaces';
import {
  DATE_FORMAT,
  DELIVERY_STATUS_BY_ID,
  ERRORS,
  PAYMENT_ORDER_STATUS_BY_ID,
} from '../../utils/constants';
import { useSnackbar } from 'notistack';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import iconBarcode from '../../assets/img/icon_barcode.svg';
import { useTranslation } from 'react-i18next';

/**
 * Both published Orders and Drafts use this component.
 * @param parcel
 * @param setOpenConfirmCancel
 * @param setParcelToCancel
 * @param statusesDelivery
 * @param isChecked
 * @param selected
 * @param setSelected
 * @constructor
 */
const ParcelTableRowItem: React.FC<{
  parcel: Parcel;
  setOpenConfirmCancel: TSetStateBoolean;
  setParcelToCancel: React.Dispatch<React.SetStateAction<Parcel | null>>;
  statusesDelivery?: StatusDelivery[];
  selected?: string[];
  setSelected?: TSetStateArrayString;
  isAdmin?: UserRoles;
}> = ({
  parcel,
  setOpenConfirmCancel,
  setParcelToCancel,
  statusesDelivery,
  selected,
  setSelected,
  isAdmin,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const accesEditable = () => {
    if (!statusesDelivery) return false;
    if (isAdmin === 'SUPER_ADMIN') return true;
    if (parcel?.trackNumber) return false;
    return true;
  };

  return (
    <TableRow>
      {selected && setSelected && (
        <TableCell padding='none' align='center'>
          <Checkbox
            className={classes.tableCheckbox}
            onChange={(e) =>
              handleSelectCheckboxItem(e, parcel.id, selected, setSelected)
            }
            checked={selected.indexOf(parcel.id) !== -1}
          />
        </TableCell>
      )}
      <TableCell className={classes.tableCell} align='center'>
        <>
          <Link to={TRoutes.SHIPMENT_DETAILS.replace(':id', parcel.id)}>
            {parcel?.barcode?.substring(0, parcel?.barcode.length - 4)}
          </Link>
          <br />
          {moment(Number(parcel.createdAt)).format(DATE_FORMAT)}
        </>
      </TableCell>
      <TableCell className={classes.tableCell} align='center'>
        <Tooltip title={`${parcel.receiver.address}`}>
          <span>
            {parcel.receiver.name},
            <br />
            {parcel.receiver.city},<br />
            {parcel?.receiver?.country?.nameEng}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.tableCell} align='center'>
        {parcel.actualAmount ? (
          <Tooltip title={t('app.actualCost')}>
            <span>{`${parcel.actualAmount} ${parcel.orderCostCurrency}`}</span>
          </Tooltip>
        ) : parcel.declaredAmount ? (
          <Tooltip title={t('app.declaredValue')}>
            <span>{`${parcel.declaredAmount} ${parcel.orderCostCurrency}`}</span>
          </Tooltip>
        ) : (
          t('app.unknown')
        )}
      </TableCell>
      <TableCell className={classes.tableCell} align='center'>
        {parcel.senderMark}
      </TableCell>
      <TableCell className={classes.tableCell} align='center'>
        {parcel.trackNumber && parcel.carrierCode && (
          <BoxCentered>
            <GetCarrierCodeImage carrierCode={parcel.carrierCode} />
            <Tooltip
              title={`${t(
                'app.trackShipment',
              )} ${parcel.carrierCode.toUpperCase()} ${parcel.trackNumber}`}
            >
              <NavLink
                to={`${TRoutes.TRACK}/${parcel.carrierCode}/${parcel.trackNumber}`}
                className={classes.btnIcon}
              >
                <span>{parcel.trackNumber}</span>
              </NavLink>
            </Tooltip>
          </BoxCentered>
        )}
      </TableCell>
      <TableCell className={classes.tableCell} align='center'>
        <ConvertStatusIdToText
          type='StatusDelivery'
          parcel={parcel}
          defaultText={t(
            DELIVERY_STATUS_BY_ID[Number(parcel.deliveryStatusId)],
          )}
        />
      </TableCell>
      <TableCell className={classes.tableCell} align='center'>
        {parcel?.typeDelivery?.isUserAccount === true ? (
          t('app.notRequired')
        ) : (
          <ConvertStatusIdToText
            type='StatusPayment'
            parcel={parcel}
            defaultText={t(
              PAYMENT_ORDER_STATUS_BY_ID[Number(parcel.paymentStatusId)],
            )}
          />
        )}
      </TableCell>

      <TableCell className={classes.tableCell} align='center'>
        <Select className={classes.selectActions} value={t('app.action')}>
          <MenuItem
            value={t('app.action')}
            disabled
            style={{ display: 'none' }}
          >
            {t('app.action')}
          </MenuItem>
          <MenuItem disableGutters divider className={classes.actionMenuItem}>
            <Link
              className={classes.actionLink}
              to={`${TRoutes.SHIPMENTS}/${parcel.id}/details`}
            >
              <SearchIcon
                className={`${classes.actionIcon} ${classes.actionIconWatch}`}
              />
              {t('app.view')}
            </Link>
          </MenuItem>
          <MenuItem disableGutters divider className={classes.actionMenuItem}>
            <a
              href={`${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${parcel?.barcode}`}
              target='_blank'
              rel='noopener noreferrer'
              className={classes.actionLink}
            >
              <img
                src={iconBarcode}
                alt='barcode'
                className={`${classes.actionIcon}`}
                style={{ width: 20, height: 20 }}
              />
              {t('app.barcode')}
            </a>
          </MenuItem>
          <MenuItem disableGutters divider className={classes.actionMenuItem}>
            <a
              href={`${process.env.REACT_APP_API_DOMAIN}/api/getReceipt/${parcel?.pathNameFile}`}
              target='_blank'
              rel='noopener noreferrer'
              className={classes.actionLink}
            >
              <SaveIcon className={`${classes.actionIcon}`} />
              {t('app.label')}
            </a>
          </MenuItem>
          <MenuItem
            disableGutters
            divider
            //@ts-ignore
            disabled={parcel?.isConsolidationPiece}
            className={classes.actionMenuItem}
          >
            <a
              href={`${process.env.REACT_APP_API_DOMAIN}api/getInvoice/${parcel?.fileTokenInvoiceXlsx}.xlsx`}
              target='_blank'
              rel='noopener noreferrer'
              className={classes.actionLink}
            >
              <SaveIcon className={`${classes.actionIcon}`} />
              {t('app.invoiceXlsx')}
            </a>
          </MenuItem>
          <MenuItem disableGutters divider className={classes.actionMenuItem}>
            <Link
              className={classes.actionLink}
              to={TRoutes.SHIPMENT_EDIT_COPY.replace(
                ':id',
                parcel.id.toString(),
              )}
            >
              <FileCopyIcon className={`${classes.actionIcon}`} />
              {t('app.copy')}
            </Link>
          </MenuItem>
          {accesEditable() && (
            <MenuItem
              disableGutters
              divider
              className={classes.actionMenuItem}
              disabled={
                !!parcel.actualAmount ||
                parcel.deliveryStatusId !==
                  getStatusIdByName(statusesDelivery as StatusDelivery[], 'new')
              }
            >
              <Link
                className={classes.actionLink}
                to={`${TRoutes.SHIPMENTS}/${parcel.id}`}
              >
                <EditIcon
                  className={`${classes.actionIcon} ${classes.actionIconEdit}`}
                />
                {t('app.correct')}
              </Link>
            </MenuItem>
          )}

          {statusesDelivery && setOpenConfirmCancel && setParcelToCancel && (
            <MenuItem
              disableGutters
              divider
              className={classes.actionMenuItem}
              disabled={disadbleCancelButton({
                statusesDelivery,
                parcel,
                isAdmin,
              })}
            >
              <Link
                className={classes.actionLink}
                to='#'
                onClick={() => {
                  if (parcel?.sentToWarehouseId) {
                    enqueueSnackbar(ERRORS.CANT_CANCEL_PARCEL_WITH_IS_SENT, {
                      variant: 'error',
                    });
                  } else {
                    setOpenConfirmCancel(true);
                    setParcelToCancel(parcel);
                  }
                }}
              >
                <CancelIcon
                  className={`${classes.actionIcon} ${classes.actionIconCancel}`}
                />
                {t('app.cancel')}
              </Link>
            </MenuItem>
          )}
        </Select>
      </TableCell>
    </TableRow>
  );
};

export default ParcelTableRowItem;

type disadbleCancelButtonType = {
  statusesDelivery?: StatusDelivery[];
  parcel: Parcel;
  isAdmin?: UserRoles;
};

const disadbleCancelButton = ({
  statusesDelivery,
  parcel,
  isAdmin,
}: disadbleCancelButtonType) => {
  if (isAdmin === 'SUPER_ADMIN') {
    return false;
  }
  if (parcel?.trackNumber) {
    return true;
  }
  if (!!parcel?.actualAmount) {
    return true;
  }
  if (
    parcel?.deliveryStatusId !==
    getStatusIdByName(statusesDelivery as StatusDelivery[], 'new')
  ) {
    return true;
  }
  return false;
};
